<template>
  <dd>
    <strong>{{ name }}：</strong> {{ value }}
  </dd>
</template>

<script>
export default {
  props: {
    name: String,
    value: String
  }
};
</script>

<style lang="scss" scoped>
dd {
  margin: 8px 0;
  color: #666;
  strong {
    display: inline-block;
    width: 100px;
    text-align: left;
    line-height: 1.6;
    font-size: 14px;
    font-weight: bolder;
    color: #000;
  }
}
</style>
