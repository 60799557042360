<template>
  <div>
    <template v-if="type === 'text'">
      <list-text
        v-for="item in data"
        :key="item.key"
        :name="item.key"
        :value="item.value"
      />
    </template>
  </div>
</template>

<script>
import Text from './Text';
export default {
  props: {
    type: {
      type: String,
      default: 'normal'
    },
    data: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  components: {
    ListText: Text
  }
};
</script>

<style></style>
