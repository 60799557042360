/**
 * 油机例行检查
 * @author Liu Qingfeng
 * @createTime 2021-08-13 14.29:13
 */
import request from '@/utils/request';

export function queryRoutineList(params) {
  return request({
    url: '/routine_inspection/data',
    method: 'GET',
    params: {
      ...params,
      type: 1
    }
  });
}

// 提交
export function saveRoutine(data) {
  return request({
    url: '/routine_inspection/tanker_save',
    method: 'POST',
    data
  });
}

export function queryRoutineHistory(params) {
  return request({
    url: '/routine_inspection/data',
    method: 'GET',
    params: {
      ...params,
      type: 0
    }
  });
}

export function exportRoutine(params) {
  return request({
    url: '/routine_inspection/export',
    method: 'GET',
    responseType: 'blob',
    params: {
      ...params,
      type: 1
    }
  });
}

export function exportRoutineHistory(params) {
  return request({
    url: '/routine_inspection/export',
    method: 'GET',
    responseType: 'blob',
    params: {
      ...params,
      type: 0
    }
  });
}
