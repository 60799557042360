<template>
  <page-header :title="title" v-model="searchParams" :breadcrumbs="pathMatch">
    <template v-slot:searchbar>
      <el-form :inline="true" size="mini">
        <AreaField v-model="searchParams.area_code" @change="getStationList" />
        <el-form-item>
          <el-select
            v-model="searchParams.site_id"
            filterable
            :clearable="true"
            placeholder="加油站（可输入）"
          >
            <el-option
              v-for="item in gasData"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            />
          </el-select>
        </el-form-item>
        <!-- <el-form-item style="margin-bottom: 0;">
			<el-date-picker v-model="searchParams.legal" placeholder="检查时间" />
				</el-form-item>-->
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            >查询</el-button
          >
          <el-button
            type="primary"
            icon="el-icon-arrow-up"
            size="mini"
            @click="handleExport"
            >导出</el-button
          >
        </el-form-item>
      </el-form>
    </template>
    <default-table
      :columns="columns"
      :dataSource="data"
      :pagination="pagination"
      @actionEvent="handleView"
      @currentChange="handleCurrentChange"
    >
      <template #index="{ scope }">{{ scope.$index + 1 }}</template>
      <template #actions="{ scope }">
        <el-button
          size="mini"
          type="primary"
          @click="handleView(scope.row, 'view')"
          >查看</el-button
        >
      </template>
    </default-table>

    <el-dialog
      :visible.sync="visible"
      width="630px"
      custom-class="dialog"
      title="查看详情"
      :close-on-click-modal="false"
    >
      <el-form class="form-wrap">
        <list type="text" :data="detailInfo" />
      </el-form>
      <div slot="footer" style="text-align: center">
        <el-button type="primary" size="mini" @click="visible = false"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </page-header>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import DefaultTable from '@/components/DefaultTable/new';
import List from '@/components/List';
import { queryRoutineHistory, exportRoutineHistory } from '@/api/routine';

import mixins from '@/mixins';

export default {
  mixins: [mixins],
  components: {
    PageHeader,
    DefaultTable,
    List
  },
  data() {
    return {
      visible: false,
      detailInfo: [],
      searchParams: {
        site_id: '',
        legal: ''
      },
      columns: [
        {
          title: '序号',
          key: 'index',
          width: 64,
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '加油站名称',
          key: 'site_name',
          width: 200
        },
        {
          title: '加油机名称',
          key: 'tanker_name',
          width: 150
        },
        {
          title: '生产厂家',
          key: 'manufacturer'
          // width: 280
        },
        {
          title: '加油机出厂编号',
          key: 'factory_num',
          width: 140
        },
        {
          title: '出厂日期',
          key: 'start_date',
          width: 100
        },
        // {
        //   title: '本次检查时间',
        //   key: 'this_time',
        //   width: 180
        // },
        {
          title: '器具编号',
          key: 'num',
          width: 180
        },
        {
          title: '检定有效期',
          key: 'expire_date',
          width: 180
        },
        {
          title: '检定机构',
          key: 'measure_exam_org',
          width: 220
        },
        {
          title: '检查时间',
          key: 'last_time',
          width: 180
        },
        {
          title: '检查备注',
          key: 'last_note',
          width: 200
        },
        {
          title: '操作',
          key: 'actions',
          width: 120,
          fixed: 'right',
          extraType: 'button',
          // extra: [{ name: 'view', label: '查看' }],
          scopedSlots: { customRender: 'actions' }
        }
      ],
      data: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10
      }
    };
  },
  created() {
    this.pathMatch = this.$router.history.current.matched;
    this.handleQuery({
      current: 1,
      pageSize: 10
    });
  },
  methods: {
    handleQuery({ current, pageSize }) {
      this.pagination.current = current || 1;
      const params = {
        page: current || 1,
        limit: pageSize || 10,
        ...this.searchParams
      };
      queryRoutineHistory(params).then(res => {
        if (res.code === 0) {
          console.log(res.data);
          this.data = res.data;
          this.pagination = {
            ...this.pagination,
            total: res.count
          };
        }
      });
    },
    handleCurrentChange(current) {
      this.handleQuery({
        current,
        pageSize: 10
      });
    },
    handleExport() {
      const params = {
        ...this.searchParams
      };

      exportRoutineHistory(params)
        .then(res => {
          this.$ExportFile(res, this.title);
          // const { code, msg } = res;
          // if (code !== 0) {
          //   this.$message({
          //     message: msg || '导出失败',
          //     type: 'error'
          //   });
          // }
        })
        .catch(err => {
          this.$message({
            message: err,
            type: 'error'
          });
        });
    },
    handleView(e) {
      console.log(e);
      this.visible = !this.visible;
      this.detailInfo = [
        { key: '加油站名称', value: e.site_name },
        { key: '加油机名称', value: e.tanker_name },
        { key: '器具编号', value: e.num },
        { key: '检定有效期', value: e.expire_date },
        { key: '检定机构', value: e.measure_exam_org },
        { key: '检查备注', value: e.last_note }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.form-wrap {
  margin: 0 auto;
  width: 440px;
}
</style>

<style lang="scss">
@import '@/styles';
.el-dialog__header {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 0 0 32px;
  box-sizing: border-box;
  color: #fff;
  background-color: $--color-primary;
}
.el-dialog__title {
  font-size: 18px;
}
.el-dialog__title,
.el-dialog__headerbtn,
.el-dialog__close {
  color: #fff !important;
}
</style>
